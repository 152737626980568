window.addEventListener("load", function () {
  const hotspotButtons = document.querySelectorAll("[data-hotspot]");
  const hotspotContentBlocks = document.querySelectorAll(".hotspot__content");
  hotspotButtons.forEach(function (hotspotButton) {
    const hotspotContentId = `#${hotspotButton.dataset.hotspot}`;
    function showPopover() {
      const hotspotContent = document.querySelector(hotspotContentId);
      if (hotspotContent.style.display !== "block") {
        hotspotContentBlocks.forEach(function (hotspotContentBlock) {
          hotspotContentBlock.style.display = "none";
        });
        hotspotContent.style.display = "block";
      }
    }
    hotspotButton.addEventListener("click", showPopover);
    hotspotButton.addEventListener("mouseenter", showPopover);
  });
  const hotspotImages = document.querySelectorAll(".hotspot__image");
  hotspotImages.forEach(function (hotspotImage) {
    hotspotImage.addEventListener("click", function () {
      hotspotContentBlocks.forEach(function (hotspotContentBlock) {
        hotspotContentBlock.style.display = "none";
      });
    });
    hotspotContentBlocks.forEach(function (hotspotContentBlock) {
      hotspotContentBlock.addEventListener("mouseleave", function (evt) {
        hotspotContentBlock.style.display = "none";
      });
    });
  });
});
