window.addEventListener("load", () => {
  const zoomers = document.querySelectorAll(".zoomer .carousel-item");

  if (zoomers.length <= 0) return;

  zoomers.forEach(el => {
    const img = el.querySelector("img");
    el.addEventListener("mousedown", () => {
      const mouseMoveFunction = evt => {
        const rec = el.getBoundingClientRect();
        const x = evt.clientX - rec.left;
        const y = evt.clientY - rec.top;
        img.style.transformOrigin = `${x}px ${y}px`;
        img.style.transform = "scale(2)";
      };
      el.onmousemove = mouseMoveFunction;
    });
    el.addEventListener("mouseup", () => {
      el.onmousemove = null;
      img.style.transformOrigin = "unset";
      img.style.transform = "unset";
    });
  });
});
