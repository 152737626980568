import { dom } from "@fortawesome/fontawesome-svg-core";

const links = document.querySelectorAll(".block-richtext a");
links.forEach(link => {
  const href = link.getAttribute("href");
  if (
    href.startsWith("http") &&
    !href.includes(
      window.location.host
        .split(".")
        .slice(-2)
        .join()
    ) &&
    !href.includes("s3.amazonaws.com")
  ) {
    if (link.dataset.hasOwnProperty("externalIgnore")) return;

    link.target = "_blank";
    link.rel = "noopener noreferrer";

    const icon = document.createElement("i");
    icon.classList.add("fas");
    icon.classList.add("fa-external-link-alt", "fa-xs");
    icon.style.marginLeft = "2px";
    link.appendChild(icon);

    const srNotice = document.createElement("span");
    srNotice.classList.add("sr-only");
    srNotice.textContent = "(opens in new tab)";
    link.appendChild(srNotice);
  }
});
dom.i2svg();
